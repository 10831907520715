import Gl from './gl';
import Blob from './gl/Blob';

import gsap from 'gsap';

import luxy from '../node_modules/luxy.js/dist/js/luxy.min.js';
let getDevice = (function(){
  let ua = navigator.userAgent;
  if(ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0){
    return 'sp';
  }else if(ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0){
    return 'tab';
  }else{
    return 'other';
  }
})();

let App = class  {
  constructor() {
    this.blob = null;
    this.addBlobs();
    this.Scroll_event();
    this.Sp_ham_menu();

    // Main animation tl
    this.tl = gsap.timeline({
      delay: 0.25,
    });

    this.tl
    .add(this.loading())
    .add(this.article())
    .add(this.animBlobs(), '-=1');
  }
  loading() {
  const loader = document.querySelector('.loading');
  window.addEventListener('load', () => {
    const ms = 400;
    loader.style.transition = 'opacity ' + ms + 'ms';
    
    const loaderOpacity = function(){
      loader.style.opacity = 0;
    }
    const loaderDisplay = function(){
      loader.style.display = "none";
    }
    setTimeout(loaderOpacity, 1000);
    setTimeout(loaderDisplay, 1000 + ms);
  });
  
    
  }
  addBlobs() {
    // size, speed, color, freq, density, strength, offset
    if( getDevice == 'other' || getDevice == 'tab' ){
      this.blob = new Blob(4.5, 0.15, 0.0, 2.0, 0.3, Math.PI * 2);
    }
    if( getDevice == 'sp' ){
      this.blob = new Blob(3.8, 0.15, 0.0, 2.0, 0.3, Math.PI * 2);
    }   
    this.blob.position.set(0, 0, 0);
    this.blob.rotation.set(0, 0, 0);

    Gl.scene.add(this.blob);
  }
  down(){
    gsap.to(this.blob.position, 0.7, { z: -50 })
    gsap.to(this.blob.mesh.material.uniforms.uNoiseStrength, 0.7, { value: 0 })
    gsap.to(this.blob.mesh.material.uniforms.uAlpha, 0.7, { value: 0 })
  }
  up(){
    gsap.to(this.blob.position, 0.7, { z: 0 })
    gsap.to(this.blob.mesh.material.uniforms.uNoiseStrength, 0.7, { value: 0.3 })
    gsap.to(this.blob.mesh.material.uniforms.uAlpha, 0.7, { value: 1 })
  }
  article() {
    // Main content
    const tl = gsap.timeline({
      defaults: {
        ease: 'power3.inOut',
      }
    });


    tl
      .from('header.general div, .subtitle div', {
        duration: 1.5,
        yPercent: -130,
        alpha:0,
        // stagger: 0.1,
      })
      .from('.cel_5 span', {
        duration: 1.5,
        yPercent: 100,
      }, '-=1.5')
      .from('.mail_area', {
        duration: 1.5,
        yPercent: -100,
      }, '-=1.5')
      .from('.sec_1 h1 span', {
        duration: 1.25,
        xPercent: -100,
        alpha:0,
      }, '-=1.5')      
      .from('.play', {
        duration: 1,
        scale: 0,
        rotate: '-150deg',
      }, '-=1.5');

    return tl;    
  }

  animBlobs() {
    // Move Threejs Blobs
    const tl = gsap.timeline({
      defaults: {
        duration: 2,
        ease: 'power3.inOut'
      },
    });

    tl
      .from(this.blob.position, { z: 5, })
      .from(this.blob.mesh.material.uniforms.uAlpha, {
        value: 0,
        stagger: 0.2
      }, 0);

    return tl;
  }
  //
  Scroll_event(){
    
    let start = document.querySelector('.start');
    let sec_1 = document.querySelector('.sec_1');
    let sec_2 = document.querySelector('.sec_2');
    let play = document.querySelector('.play');
    let replay = document.querySelector('.replay');
    const windowHeight = window.innerHeight;
    let webgl_area = document.querySelector(".webgl");

    window.addEventListener('scroll', () => {
      let scroll = document.documentElement.scrollTop || document.body.scrollTop;
      let rect_2_top = sec_2.getBoundingClientRect().top;
      const currentPos = window.pageYOffset;

      if(currentPos > 0){
        kieru();
        this.down();
      }
      if(currentPos == 0){
        sec_1.classList.remove("shadow");
        sec_2.classList.add("shadow");
        play.classList.remove("shadow_ply");
        replay.classList.remove("sun_ply");
        this.up();
        gsap.to(webgl_area, 0.7, { alpha: 1 }) 
      }
      if ((windowHeight / 2) <= currentPos * 5) { 
        replay.classList.add("sun_ply");
      } 
      
    });
    //
    play.addEventListener('click',function(){
      kieru();
      this.down();
    }.bind(this),false); 
    function kieru() {
      sec_1.classList.add("shadow");
      sec_2.classList.remove("shadow");
      play.classList.add("shadow_ply");
      gsap.to(webgl_area, 0.7, { alpha: 0 }) 
    }
    replay.addEventListener('click',function(){
      start.scrollIntoView(true);
      this.classList.remove("sun_ply");
    },false); 
    // 

  }
  Sp_ham_menu (){
    const timeline =  gsap.timeline({}); 
    const tgt = document.querySelector(".cel_5 span");
    const mail_area = document.querySelector(".mail_area");
    const bdy = document.body;
    let rtn = document.querySelector(".l-gnav__layer .return_obj");
    let modalArea = document.querySelector(".l-gnav__layer");
    tgt.parentNode.addEventListener('click', function() {     
        bdy.classList.toggle('is-active');
        bodyScrollPrevent(true);
    }, false);
    rtn.addEventListener('click',function(){
      tgt.click();
      bodyScrollPrevent(false,modalArea);
    },false); 
    mail_area.addEventListener('click',function(){
      tgt.click();
      bodyScrollPrevent(true);
    },false); 
    //
    function bodyScrollPrevent(flag, modal){
      let scrollPosition;
      const body = document.getElementsByTagName('body')[0];
      const ua = window.navigator.userAgent.toLowerCase();
      const isiOS = ua.indexOf('iphone') > -1 || ua.indexOf('ipad') > -1 || ua.indexOf('macintosh') > -1 && 'ontouchend' in document;
      const scrollBarWidth = window.innerWidth - document.body.clientWidth;
      if(flag){
        body.style.paddingRight = scrollBarWidth + 'px';
          body.style.overflow = 'hidden';
      } else if(!flag) {
        addEventListenerOnce(modal,'transitionend',function(){
          body.style.paddingRight = '';
            body.style.overflow = '';
        });
      }
      function addEventListenerOnce(node, event, callback) {
        const handler = function(e) {
          callback.call(this, e);
          node.removeEventListener(event, handler);
        };
        node.addEventListener(event, handler);
      }
    }

  }
}
new App();

var perfEntries = performance.getEntriesByType("navigation");
var type = null;
perfEntries.forEach(function(pe){
  type = pe.type;
});
if(type == 'reload'){
  window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
}
if( getDevice == 'other' ){
luxy.init();
}